<script>
import Layout from "../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import contactsTable from "./components/contactsTable_beta";
import appConfig from "@/app.config";
import SideButtons from '@/components/side-buttons.vue';
import Multiselect from 'vue-multiselect';
import Swal from "sweetalert2";
import rejectionModal from "../../../../common/modals/rejectionModal";
import approvalModal from "../../../../common/modals/approvalModal";
import { fetchLifebasesApi, fetchContractStdServicesApi } from "@/api/common";
import { getDropDownElementsByTypeApi, loadControlCriteriasApi, fetchContractsListApi } from "@/api/common";

export default {
  page: {
    title: "",
    meta: [{ name: "description", content: appConfig.description }],
  },
  import: {
    fetchLifebasesApi,
    fetchContractStdServicesApi,
    getDropDownElementsByTypeApi,
    loadControlCriteriasApi,
    fetchContractsListApi
  },

  components: {
    Layout,
    PageHeader,
    contactsTable,
    SideButtons,
    Multiselect,
    rejectionModal,
    approvalModal
  },
  data() {
    return {
      controls: [],
      currentSelectedControl : null,
      shouldReloadCriterias: false,
      contracts: [],
      disableForm : true,
      fullPageLoading: true,
      formStep: 0,
      currentSelectedContract: null,
      active: true,
      shouldClearAllForms: false,
      selectedCampsData: [],
      noteAvg: 0,
      noteEmoji : 'sad',
      title: "",
      controlForm: {
        date: new Date().toISOString().substr(0, 10),
        criterias: [],
        score: "",
        contract: "",
        totalScore: 0,
        type: this.$route.params.category,
        comments: "",
      },
      criterias: [],
      type : this.$route.params.category,
      items: [
        {
          text: "Master Data",
          to: { name: 'master_data' }
        },
        {
          text: "Fiche Contrat",
          to: { name : 'base.contracts.contracts.index' }
        },
        {
          text: "Gestion des contrats",
          active: true,
        },
      ],
    };
  },

  watch: {
    noteAvg(){
      this._updateEmoji(this.noteAvg);
    },

    shouldReloadCriterias(){
      if(this.shouldReloadCriterias){
        this.controlForm.criterias.forEach(element => {
            element.score = 0;
            element.comments = "";
        });
      }
    }
  },

  mounted() {
    this.loadContracts();
    this.loadControlCriterias();
    this.fetchControlsList();
    // this.fetchLifebasesList();
  },

  methods: {

    loadContracts(){
      var loader = this.$loading.show();
      fetchContractsListApi('approved')
      .then((res) => {
        loader.hide();
        this.contracts = res.data.list
      })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {
          //Perform action in always
        });
    },  

    _updateEmoji(avg){
      var sadLevel = 4;
      var mehLevel = 6;
      var happyLevel = 10;
      var emoji = 'sad';
      
      if(avg >= 0 && avg <= sadLevel){
        emoji = 'sad';
      }else if(avg > sadLevel && avg <= mehLevel){
        emoji = 'confused';
      }else if(avg > mehLevel && avg < happyLevel){
        emoji = 'smile';
      }else {
        emoji = 'love';
      }
      this.noteEmoji = emoji;
    },

    computeAvg(){
      var avg = 0;
      var countCriterias = this.controlForm.criterias.length;

      var totalNotes = 0;

      this.controlForm.criterias.forEach(element => {
        totalNotes += parseFloat(element.score);
      });
      avg = parseFloat( parseFloat(totalNotes) / parseFloat(countCriterias) ); 
      this.noteAvg = avg.toFixed(2); 
      this.controlForm.totalScore = avg.toFixed(2); 
      this._updateEmoji(avg);
    },

    loadControlCriterias(){
      loadControlCriteriasApi(this.type)
      .then((res) => {
        var criterias = res.data.list;
        criterias.forEach(criteria => {
          this.controlForm.criterias.push({
            id: criteria.id,
            display: criteria.name,
            score: 0,
            comment: "",
          });
      });
      });
    },

    fetchContractDetails(uuid = null) {
      let loader = this.$loading.show();
      this.$http
        .post("/contracts/contracts/getSingleContractData/" + uuid)
        .then((res) => {
          var status = res.data.status;
          var contract = res.data.data.contract;
          loader.hide();
          switch (status) {
            case 200:
              this.currentSelectedContract = contract;
              this.shouldClearAllForms = false;
              this.updateTabsUi();
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    handleLoadContractDataToFormComponent(selectedContract) {
      if (selectedContract) {
        this.fetchContractDetails(selectedContract.uuid);
      }
      
    },
  
    deleteSelectedControl(){
      if(this.currentSelectedControl){
        if(this.currentSelectedControl.status.plain == 'draft'){
          Swal.fire({
            title: "Êtes-vous sûr de supprimer le contrôl N°: " + this.currentSelectedControl.ref + "?",
            text: "Vous ne pourrez pas annuler cela!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Supprimer!",
          }).then((result) => {
            if (result.value) {
              var loader = this.$loading.show();
              this.$http
                .post("/ops/controls/delete/" + this.currentSelectedControl.uuid)
                .then((res) => {
                  var status = res.data.original.status;
                  loader.hide();
                  switch (status) {
                    case 200:
                      this.$toast.success(res.data.original.msg);
                      this.currentSelectedControl = null;
                      this.resetForm();
                      this.fetchControlsList();
                      break;
    
                    case 500:
                      this.$toast.warning(res.data.original.msg);
                      break;
                  }
                })
                .catch((error) => {
                  loader.hide();
                  this.$toast.error(error.message);
                })
                .finally(() => {});
            }
          });
        }else{
          this.$toast.error("Impossible de supprimer le control N°: <strong>"+ this.currentSelectedControl.ref +"</strong>");
        }
      }else{
        this.$toast.error("Veuillez selectionner un control a supprimer.");
      }
    },

    openEditSelectedControl(){
        if(this.currentSelectedControl){
          if(this.currentSelectedControl.status.plain == 'draft'){
            this.disableForm = false
          }else{
            this.$toast.error("Impossible de modifer le control N°: <strong>"+ this.currentSelectedControl.ref +"</strong>");
          }
        }else{
          this.$toast.error("Veuillez selectionner un control");
        }
      
    },

    fetchControlsList(){
      var loader = this.$loading.show();
      this.$http
        .post("/ops/controls/list", {
          controlType: this.$route.params.category
        })
        .then((res) => {
          loader.hide();
          this.controls = res.data.list;
        })
        .catch(() => {
          loader.hide();
          // error.response.status Check status code
        })
        .finally(() => {
          loader.hide();
          //Perform action in always
        });
    },

    addNewControl(){
      this.disableForm = false;
      this.resetForm();
    },

    cancelForm(){
      this.disableForm = true;
      this.resetForm();
    },

    resetForm(shouldReloadCriterias = false){
      this.controlForm.date = new Date().toISOString().substr(0, 10);
      this.controlForm.score = "";
      if(shouldReloadCriterias){
        this.controlForm.criterias = [];
      }
      this.controlForm.contract = "";
      this.controlForm.totalScore = 0;
      this.controlForm.type = this.$route.params.category;
      this.controlForm.comments = "";
      this.noteAvg = 0;
      this.emoji = 'sad';
      this.controlForm.modelType = null;
      this.controlForm.ref = null;
      this.controlForm.id = null;
      this.controlForm.uuid = null;
      this.shouldReloadCriterias = shouldReloadCriterias;
    },



    submitControl(){
      this.submitted = true;
      var loader = this.$loading.show();
      this.$v.$touch();
      var router = this.$router;
      this.$http
        .post("/ops/controls/store",
          this.controlForm
        )
        .then((res) => {
          loader.hide();
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              this.resetForm();
              this.fetchControlsList();
              break;

            case 500:
              
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {
          loader.hide();
        });
    },

    handleLoadControlDataToFormComponentEvent(controlUid = null){
      let loader = this.$loading.show();
      this.$http
        .post("/ops/controls/getSingleControlDetails/" + controlUid.uuid)
        .then((res) => {
          var status = res.data.status;
          var control = res.data.data.control;
          loader.hide();
          switch (status) {
            case 200:
              this.currentSelectedControl = control;
              this.controlForm.modelType = control.modelType;
              this.controlForm.ref = control.ref;
              this.controlForm.id = control.id;
              this.controlForm.uuid = control.uuid;
              this.controlForm.date = control.date;
              this.controlForm.contract = control.contract;
              this.controlForm.totalScore = parseFloat(control.score);
              this.controlForm.criterias = control.criterias;
              this.noteAvg = parseFloat(control.score);
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {
          loader.hide();
        });
    },
  },

  validations: {
    controlForm: {
      // date: { required },
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- contracts header with table and search -->
    <SideButtons 
    :save="items" 
    :contract="currentSelectedContract" 
    @info ="items" 
    @add="addNewControl" 
    @save="items" 
    @edit="openEditSelectedControl" 
    @confirm="items" 
    @validate="items" 
    @delete="deleteSelectedControl"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button
                block
                @click="active = !active"
                style="background:#e5e9f0; color:black !important; border:none !important;"
              >
                <div class="row">
                  <div class="col text-left mt-1">
                    <i class="mdi mdi-format-list-bulleted"></i> Liste des
                    Contrôls
                  </div>
                  <div class="col text-right">
                    <i
                      v-if="active"
                      class="mdi mdi-chevron-up font-size-18"
                    ></i>
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i>
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse
              id="accordion-1"
              :visible="active"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body class="shadow-lg">
                <div class="table-responsive mb-0 shadow">
                  <contacts-table
                    :controls="controls"
                    @loadControlDataToFormComponent="handleLoadControlDataToFormComponentEvent"
                  />
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">
              Fiche contrôl
            </h4>
              <template v-if="currentSelectedControl">
                <b-button v-if="currentSelectedControl.status.plain == 'draft'" variant="success" v-b-modal.approvalModal>
                  <i class="bx bx-check-double font-size-16 align-middle mr-2"></i>
                  Approuver
                </b-button>

                <b-button v-if="currentSelectedControl.status.plain == 'draft'" variant="danger" class="ml-2" v-b-modal.rejectionModal>
                  <i class="bx bx-block font-size-16 align-middle mr-2"></i>
                  Rejeter
                </b-button>
              </template>
            <fieldset :disabled="disableForm">
              <form
              class="needs-validation"
              @submit.prevent="submitControl"
              enctype="multipart/form-data"
            >
           
              
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="formrow-password-input">N° Contrat</label>
                    <multiselect v-model="controlForm.contract" :select-label="''"  track-by="id" label="ref" placeholder="Select one" :options="contracts" :allow-empty="false">
                      <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.ref }}</strong></template>
                    </multiselect>
                    
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="formrow-inputCity">Date *</label>
                    <input
                      type="date"
                      v-model="controlForm.date"
                      class="form-control"
                      name=""
                      id=""
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <label for="">Moyenne: </label>
                  <h3 style="">
                    {{ noteAvg }}/10
                    <img
                      class="rounded-circle avatar-sm"
                      :alt="noteAvg"
                      :title="noteAvg"
                      :src="require('@/assets/images/base/controls/' + noteEmoji + '.png')"
                      data-holder-rendered="true"
                    />
                    </h3>
                </div>
              </div>

              <h3>Notes:</h3>
              <hr />
              <div>
                <div
                  v-for="criteria in controlForm.criterias"
                  :key="criteria.display"
                  class="row"
                >
                  <div class="form-group col-lg-3">
                    <label for="message">Critère</label>
                    <input
                      id="subject"
                      disabled
                      readonly
                      v-model="criteria.display"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  
                  <div class="form-group col-lg-5">
                    <label for="subject">Score</label>
                    <star-rating :read-only="disableForm" @rating-selected="computeAvg" :max-rating="10" :star-size="40" :increment="0.5" v-model="criteria.score"></star-rating>
                   
                  </div>

                  <div class="form-group col-lg-4">
                    <label for="message">Commentaires</label>
                    <input
                      id="subject"
                      v-model="criteria.comment"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <br />

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="formrow-inputCity">Commentaires</label>
                    <textarea
                      class="form-control"
                      v-model="controlForm.comments"
                      cols="30"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  
                  <button
                  :disabled="$v.controlForm.$invalid"
                  class="btn btn-primary float-right ml-2"
                  type="submit"
                >
                <i class="fas fa-save"></i>
                  Enregistrer
                </button>
                <b-button @click="cancelForm" variant="danger" class="btn btn-light btn-label  mr-2 float-right">
                      <i class="fas fa-times-circle text-white label-icon"></i> Annuler
                  </b-button>
                </div>
              </div>
            </form>
            <approval-modal
                  :modelRef="controlForm.ref"
                  :modelId="controlForm.id"
                  :modelType="controlForm.modelType"
                />
                <rejection-modal
                  :modelRef="controlForm.ref"
                  :modelId="controlForm.id"
                  :modelType="controlForm.modelType"
                />
            </fieldset>
            
          </div>
        </div>
      </div>
    </div>

    
  </Layout>
</template>
