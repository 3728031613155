<script>
import Swal from "sweetalert2";
export default {
  props: {
    controls: [],
  },
  data() {
    return {
      tableData: [],
      selectedContract: null,
      title: "Contrats",
      items: [
        {
          text: "Contrats",
          href: "javascript:void(0)",
        },
        {
          text: "Contrats",
          active: true,
        },
      ],
    };
  },

  watch: {
    selectedContract(event){
      if(this.selectedContract != null && event.length == 1){
        this.$emit('loadControlDataToFormComponent', this.selectedContract[0]);
      }else{
        this.$emit('clearAllContractForms', true);
      }
    }
  },

  methods: {

    deleteContract(contRef, contUid) {
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer " + contRef + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/contracts/contracts/delete/" + contUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  Swal.fire("Félicitations!", res.data.original.msg, "success");
                  _this.refreshContractsTable();
                  break;

                case 500:
                  Swal.fire("Avertissement!", res.data.original.msg, "warning");
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
  },
};
</script>

<template>
  <v-table 
    :data="controls" 
    class="table table-hover"
    selectionMode="single"
    selectedClass="table-info"
    @selectionChanged="selectedContract = $event"
    >
    <thead slot="head">
      <th>Référence</th>
      <th>Contrat</th>
      <th>Prestataire</th>
      <th>Date d'exécution</th>
      <th>Effectué par</th>
      <th>Note totale</th>
      <th>Status</th>
    </thead>
    <tbody slot="body" slot-scope="{ displayData }">
      <v-tr v-for="row in displayData" :key="row.uuid" :row="row">
        <td>{{ row.ref }}</td>
        <td>{{ row.contract.ref }}</td>
        <td>{{ row.contract.supplier.prestname }}</td>
        <td>{{ row.date }}</td>
        <td>{{ row.performedBy.fullName.toUpperCase() }}</td>
        <td>{{ row.score }}/10</td>
        <td><span v-html="row.status.html"></span></td>
      </v-tr>
    </tbody>
  </v-table>
</template>
